import React from "react"
import { Link } from "gatsby"
import logoBlack from "../images/logo-black.svg"
import mobileBackground from "../images/mobilebackground.png"
import AnnouncementBar from "../components/announcement-bar"

import "../scss/navbar.scss"
import "../scss/contactForm.scss"
import "../scss/auditMenu.scss"

class Navbar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      isOpen: false,
      navBarActiveClass: "",
    }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({ navBarActiveClass: "is-active" })
          : this.setState({ navBarActiveClass: "" })
      }
    )
  }

  render() {
    return (
      <nav
        className={this.props.slug === "/" ? "navbar is-transparent" : "navbar"}
        role="navigation"
        aria-label="main-navigation"
      >
        <AnnouncementBar />
        <div className="container-max-width">
          <div className="container">
            <div className="navbar-brand">
              <Link
                to="/"
                className="navbar-item navbar-item-logo"
                title="Logo"
              >
                <img
                  src={logoBlack}
                  alt="Next and Co"
                  style={{ height: "17px" }}
                />
              </Link>
              {/* Hamburger menu */}
              <button
                className={`navbar-burger burger ${this.state.navBarActiveClass}`}
                data-target="navMenu"
                label="Toggle Navigation"
                onClick={() => this.toggleHamburger()}
              >
                <span className="top" />
                <span className="middle" />
                <span className="bottom" />
              </button>
            </div>
            <div
              id="navMenu"
              style={{
                backgroundImage: `url(${mobileBackground})`,
                backgroundPosition: `center center`,
                backgroundSize: `100% 50%`,
                backgroundRepeat: `no-repeat`,
                backgroundAttachment: `fixed`,
              }}
              className={`navbar-menu ${this.state.navBarActiveClass}`}
            >
              <div className="navbar-items">
                <Link className="navbar-item" to="/capabilities">
                  Capabilities
                </Link>
                <Link className="navbar-item" to="/case-studies">
                  Case Studies
                </Link>
                <Link className="navbar-item" to="/about">
                  About Us
                </Link>
                <Link className="navbar-item" to="/our-audits">
                  Our Audits
                </Link>
                <Link className="navbar-item" to="/the-next">
                  The Next
                </Link>
                <Link className="navbar-item" to="/contact">
                  Contact
                </Link>
              </div>

              <div className="navbar-footer">
                <div className="links-bar">
                  <a className="phone-number" href="tel:1300191950">
                    1300 191 950
                  </a>
                </div>
                <div className="address">
                  <p>Level 3 424 St Kilda Road, Melbourne, Victoria 300</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default Navbar
